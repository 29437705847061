@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}


.app-background {
  background: linear-gradient(135deg, #000367, #000367, #0045a4, #462fa5, #8f19a6);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 50px 0;
  box-sizing: border-box;
  position: relative;
}

/* Logo arkaplan deseni */
.app-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./img/icon.png') repeat;
  background-size: 30px;  /* Logoyu 30px genişlikte ayarla */
  opacity: 0.1;  /* Logonun belirginliği */
}

.app-background > div {
  position: relative;
  margin: 20px;
}

.text-center {
  p{
    font-size: 18px;
  }
}

.container {
  width: 100%;
  height: 100%;
  max-width: 600px;  /* Maximum width */
  min-width: 300px;  /* Minimum width for mobile devices */
  max-height: 100%;
}

.iptal{
  p {
    font-size: 17px;
  }

  p:nth-child(3) {
    color: #000000;
    font-size: 12px;
  }
}


/* Adjust padding for smaller screens */
@media (max-width: 768px) {
  .container {
    padding: 2rem;

  }
}

/* Adjust padding for very small screens */
@media (max-width: 480px) {
  .container {
    padding: 1.9rem;
    
  }
}

/* Make sure the app container is centered vertically and horizontally */
@media (max-width: 600px) {
  .app-background {
    
  }
}